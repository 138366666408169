import React from 'react';

import Shape1 from '../../assets/img/shape/shape-1.png';

import HeroCircle1 from '../../assets/img/icon/hero/hero-circle.png';
import HeroCircle2 from '../../assets/img/icon/hero/hero-circle-2.png';
import HeroSquare1 from '../../assets/img/icon/hero/hero-square.png';
import HeroSquare2 from '../../assets/img/icon/hero/hero-square-2.png';
import HeroDot from '../../assets/img/icon/hero/hero-dot.png';
import HeroTriangle from '../../assets/img/icon/hero/hero-triangle.png';

import Hero1 from '../../assets/img/hero/hero-1.jpg';
import Hero2 from '../../assets/img/hero/hero-2.jpg';
import Hero3 from '../../assets/img/hero/hero-3.jpg';



const HeroArea = () => {

   const handleClick = () => {
      const element = document.getElementById('product');
      if (element) {
         element.scrollIntoView({behavior: 'smooth', inline: "nearest"});
      }
   };

   return (
      <>
         {/*  bg shape area start  */}
         <div className="bg-shape">
         <img src={Shape1} alt=""/>
         </div>
         {/* bg shape area end  */}
         <section className="hero__area hero__height d-flex align-items-center">
            <div className="hero__shape">
               <img className="circle" src={HeroCircle1} alt="circle"/>
               <img className="circle-2" src={HeroCircle2} alt="circle"/>
               <img className="square" src={HeroSquare1} alt="square"/>
               <img className="square-2" src={HeroSquare2} alt="square2"/>
               <img className="dot" src={HeroDot} alt="dot"/>
               <img className="triangle" src={HeroTriangle} alt="triangle"/>
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-8">
                     <div className="hero___content mb-100">
                        <h2 className="section__title">
                           Welcome to<span> IntellApps </span><br/>
                           Themes Collection.
                        </h2>
                        <h5 className="fw-normal">This is a place where you can get an idea for your dream website. <br/>
                        <br/> However, we still will suggest you the best option, the best customization.</h5>
                        <div className="col-xxl-12">
                           <div className="category__more mt-60 text-start">
                              <button onClick={handleClick}>
                                 <a className="m-btn m-btn-2"> View all categories</a>
                              </button>
                           </div>
                        </div>
                        
                        {/* <div className="hero__search">
                           <form action="#">
                              <div className="hero__search-inner d-xl-flex">
                                 <div className="hero__search-input">
                                    <span><i className="far fa-search"></i></span>
                                    <input type="text" placeholder="Search for templates"/>
                                 </div>
                                 <button type="submit" className="m-btn ml-20"> <span></span> search</button>
                              </div>
                           </form>
                        </div> */}
                     </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                     <div className="hero__thumb-wrapper scene ml-70 p-relative">
                        <div className="hero__thumb one d-none d-lg-block">
                           <img className="layer" data-depth="0.2" src={Hero1} alt=""/>
                        </div>
                        <div className="hero__thumb two d-none d-md-block">
                           <img className="layer" data-depth="0.3" src={Hero2} alt=""/>
                        </div>
                        <div className="hero__thumb three d-none d-md-block">
                           <img className="layer" data-depth="0.4" src={Hero3} alt=""/>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HeroArea;