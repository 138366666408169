import { Link } from 'react-router-dom';
import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { useSelector } from 'react-redux';
//import useAuth from '../../hooks/useAuth';

import LogoWhite from '../../assets/img/logo/logo-white.png';


const SidebarMenu = ({ show, handleClose }) => {
   const totalCartQuantity = useSelector(state => state.products.cartTotalQuantity);
   //const { user, logout } = useAuth();
   return (
      <>

         <div >
            <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
               <Offcanvas.Header closeButton>
                  <div className="logo">
                     <Link to="/home">
                        <a>
                           <img src={LogoWhite} alt="logo" />
                        </a>
                     </Link>
                  </div>
               </Offcanvas.Header>

               <Offcanvas.Body>

                  <div className="">
                     <div className="">

                        <div className="sidebar__content">

                           <div className="mobile-menu">
                              <nav id="mobile-menu">
                                 <ul>
                                    {/* <Collapsible trigger={<Link to="/home">Home</Link>} triggerTagName="div"
                                       triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                                       <ul className="sidebar_sub_menu submenu text-white">
                                          <li><Link to="/home"><a >Home 1</a></Link></li>
                                          <li><Link to="/home-two"><a >Home 2</a></Link></li>
                                          <li><Link to="/home-three"><a >Home 3</a></Link></li>
                                       </ul>
                                    </Collapsible>

                                    <Collapsible trigger={<Link to="/product">Themes</Link>} triggerTagName="div"
                                       triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                                       <ul className="sidebar_sub_menu submenu text-white">
                                          <li><Link to="/product"><a>Product</a></Link></li>
                                          <li><Link to="/product-details"><a>Product Details</a></Link></li>
                                       </ul>
                                    </Collapsible> */}

                                    <div className='single_link iconAdd '>
                                       <li><a href="https://www.intellapps.io/" target="_blank" rel="noopener noreferrer">Home</a></li>
                                    </div>

                                    {/* <div className='single_link iconAdd'>
                                       <li><Link to="/support"><a>Support</a></Link></li>
                                    </div>

                                    <Collapsible trigger={<Link to="/product">Pages</Link>} triggerTagName="div"
                                       triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                                       <ul className="sidebar_sub_menu submenu text-white">
                                          <li><Link to="/about"><a >About</a></Link></li>
                                          <li><Link to="/documentation"><a >Documentation</a></Link></li>
                                          <li><Link to="/cart"><a >Cart Page</a></Link></li>
                                          <li><Link to="/wishlist"><a >Wishlist</a></Link></li>
                                          <li><Link to="/checkout"><a >Checkout</a></Link></li>
                                          <li><Link to="/pricing"><a >Pricing</a></Link></li>
                                          <li><Link to="/sign-up"><a >Sign Up</a></Link></li>
                                          <li><Link to="/login"><a >Log In</a></Link></li>
                                       </ul>
                                    </Collapsible>

                                    <Collapsible trigger={<Link to="/blog">Blog</Link>} triggerTagName="div"
                                       triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                                       <ul className="sidebar_sub_menu submenu text-white">
                                          <li><Link to="/blog"><a >Blog</a></Link></li>
                                          <li><Link to="/blog-details"><a >Blog Details</a></Link></li>
                                       </ul>
                                    </Collapsible> */}

                                    <div className='single_link iconAdd border-0'>
                                       <li><a href="https://www.intellapps.io/contact/" target="_blank" rel="noopener noreferrer">Contact</a></li>
                                    </div>

                                 </ul>
                              </nav>
                           </div>

                           <div className="sidebar__action d-flex align-items-center">
                              {/* <div className="sidebar__login mt-15">
                                 {user?.email ? < a onClick={logout} style={{ cursor: 'pointer' }} className='d-flex align-items-center'> <i className="fal fa-sign-out-alt"></i> Log Out</a> :
                                    <Link to="/login">
                                       < a> <i className="far fa-unlock"></i> Log In</a>
                                    </Link>}
                              </div> */}
                              {/* <div className="sidebar__cart mt-20">
                                 <Link to="/cart">
                                    <a className="cart-toggle-btn">
                                       <i className="far fa-shopping-cart"></i>
                                       <span>{totalCartQuantity}</span>
                                    </a>
                                 </Link>
                              </div> */}
                           </div>
                        </div>
                     </div>
                  </div>

               </Offcanvas.Body>
            </Offcanvas>
         </div>
      </>
   );
};

export default SidebarMenu;